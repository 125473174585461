// Components: Cards
// --------------------------------------------------------------------------

@include card('%card--cta', false, false, (2 * $root-vgrid));

%card--cta {
    text-align: center;

    .typography {
        margin: 0;
    }

    i {
        display: block;
        font-size: 2rem;
        margin-bottom: $root-vgrid;
    }
}

@include card('%card--default', false, false, 0);

%card--default {
    color: $root-color;
}


.card--partner {
    @extend %card--cta;

    ul {
        list-style: none;

        a {
            color: $color-primary;
        }
    }

    &__title {
        @extend %title-small;
        @extend %title-center;
        font-weight: 700;
    }
}

.cards--home-ctas {
    [gr-grid='block']:nth-child(even) .card--home-cta {
        background: lighten($color-secondary, 10%);
    }
}

.card--home-cta {
    @extend %card--cta;
    background: $color-secondary;

    i {
        color: $white;
    }

    &__title {
        @extend %title--underlined;
        @extend %title-center;
    }
}

.card--map {
    @extend %card--cta;
    background: lighten($color-secondary, 10%);

    i {
        color: $root-color;
    }

    &__title {
        @extend %title-small;
        @extend %title-center;
        font-weight: 700;
    }
}

@include flex-banner('.card--activity', auto, 0, 0);

.card--activity {
    @include keep-ratio('1/1');
    min-height: 357px; // firefox fix

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 12;
        background-color: transparentize($black, .5);
    }

    &__inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $white;
        z-index: 13;
    }

    &__title {
        @extend %title--underlined;
        @extend %title-center;

    }

    a {
        position: absolute;
        bottom: $root-vgrid;
        left: 50%;
        transform: translateX(-50%);
    }

    @include mq(md) {
        a {
            opacity: 0;
        }

        a,
        &__cover img {
            transition: $transition-normal ease-in-out;
        }

        &:hover &__cover img {
            transform: scale(1.1);
        }

        &:hover a {
            opacity: 1;
        }
    }
}

@include card('.card--client', 1px solid $color-border, false, $root-vgrid);

.cards--clients {
    [gr-grid*='block'] {
        flex-basis: 50%;
        max-width: 50%;
    }
}

.card--client {
    a {
        @include keep-ratio('16/9');

        img {
            filter: grayscale(20%);
            transition: border-color $transition-normal ease-in-out;
        }

        svg * {
            fill: color(greys, xlight);
        }

        &:hover img {
            filter: none;
        }

        &:hover svg {
            fill: auto;
        }
    }
}

@include card('.card--contact', 1px solid $color-border, false, (3 * $root-vgrid));

.card--contact {
    background: $white;
    transition: transform $transition-normal ease-in-out, box-shadow $transition-normal ease-in-out;
    text-transform: uppercase;
    text-align: center;

    a {
        color: $color-primary;
        display: block;
    }

    &:hover {
        transform: translateY(-.5em);
        box-shadow: 0 5px 7.5px rgba(0, 0, 0, .3);
    }
}

.card--blogpost {
    color: $root-color;

    & + & {
        margin-top: $root-vgrid;
        padding-top: $root-vgrid;
        border-top: 1px solid $color-border;
    }

    [gr-lazyloader] {
        @include keep-ratio('16/9');
    }

    .blogpost__title {
        @extend %title-small;
        font-weight: 700;
    }
}

.card--blogpost--popular {
    color: $root-color;
    align-items: center;
    margin-top: $root-vgrid;
    flex-direction: row;

    [gr-lazyloader] {
        @include keep-ratio('1/1');
    }

    .blogpost__title {
        @extend %title-small;
        font-weight: 700;
    }

    [gr-grid*="sm-"]:nth-child(1) {
        flex: 1 1 0;
    }

    [gr-grid*="sm-"]:nth-child(2) {
        flex: 3 1 0;
    }
}


@include card('.card--blogpost--related', false, false, 0);

.cards--blogposts--related {
    .btn--center {
        margin-top: $root-vgrid;
    }
}

.card--blogpost--related {
    color: $root-color;
    transition: transform $transition-normal ease-in-out;

    &__footer {
        text-align: center;
    }

    &:hover {
        transform: translateY(-.5em);
    }

    .blogpost__title {
        @extend %title-small;
        font-weight: 700;
    }
}

@include card('.card--package', false, false, $root-vgrid);

.card--package {
    position: relative;
    background: $white;
    border: 1px solid $color-border;
    transition: $transition-normal ease-in-out;
    //text-align: center;

    &__title {
        @extend %title-small;
        font-weight: 700;
        margin-top: $root-vgrid;
        text-align: center;
    }

    &__footer {
        padding-bottom: $root-vgrid;
        text-align: center;
    }

    &:hover {
        box-shadow: 0 5px 7.5px rgba(0, 0, 0, .3);
        background: color(greys, xlight);
    }
}

@include card('.card--nav__sub', false, false, 0);

.card--nav__sub {

    img {
        border: 5px solid transparent;
        transition: border-color $transition-normal ease-in-out;
    }

    @include mq(md) {
        &:hover {
            color: $color-secondary;

            img {
                border-color: currentColor;
            }

            span {
                color: currentColor;
            }
        }
    }
}

.card--tabs {
    @extend %card--default;

    [gr-lazyloader] {
        @include keep-ratio('16/9');
    }

    .card--tabs__title {
        @extend %title-small;
        font-weight: 700;
    }

    p {
        font-size: .875em;
    }
}


.card--quote {
    color: $root-color;
    margin-top: $root-vgrid;

    [gr-lazyloader] {
        @include keep-ratio('16/9');
        margin-bottom: $root-vgrid;
    }

    .card--quote__title {
        @extend %title-small;
        font-weight: 700;

        &::before {
            font-family: $de-icon-font;
            content: $eastman-quote;
            display: inline-block;
            font-size: .875rem;
            width: 2.25rem;
            height: 2.25rem;
            border: 1px solid $root-color;
            line-height: (strip-unit(2.25rem) * $root-font-size);
            text-align: center;
            border-radius: 100%;
            margin-right: .5rem;
        }
    }

    blockquote {
        margin-top: $root-vgrid;
    }
    // [gr-grid*="sm-"]:nth-child(1) {
    //     flex: 1 1 0;
    // }

    // [gr-grid*="sm-"]:nth-child(2) {
    //     flex: 3 1 0;
    // }
}
