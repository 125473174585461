// ==========================================================================
// Breadcrumb
// ==========================================================================

@mixin breadcrumb($class: '.breadcrumb', $separator: $gr-icon-angle-right) {

    #{$class} {
        list-style: none;
        font-size: 0;

        li {
            display: inline-block;
            font-size: 1rem;
        }

        li:not(:last-child) {
            &::after {
                font-family: $gr-icon-font;
                content: $separator;
                display: inline-block;
                margin: 0 .5em;
            }
        }

        li:last-child {
            a {
                cursor: default;
            }
        }
    }
}
