// ==========================================================================
// Navigation
// ==========================================================================

@include nav('.nav', $nav-height, $nav-mobile-panel-width, md);

.nav {
    position: absolute;

    & > div {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: $nav-height;
        padding-left: 0;
        padding-right: 0;
    }

    @include mq(md) {
        padding-top: (.5 * $nav-height);
        height: $nav-height;
    }


    &__nav-icon {
        color: $white;
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;

        span {
            display: none;

            @include mq(xs) {
                display: block;
            }
        }
    }

    &__brand {
        img,
        svg {
            display: inline-block;
            height: 61px;
            width: 100px;
        }

        @include mq(xs) {
            img,
            svg {
                height: 100px;
                width: 162px;
            }
        }

        @include mq(md) {
            position: absolute;
            top: 30px;
            z-index: 42;
        }
    }

    &__panel {
        background: $white;
        padding: $root-vgrid (.5 * $grid-gutter-width);

        @include mq(md) {
            background: none;
            padding: 0;
            min-height: inherit;
            align-items: flex-end;
            font-size: 0;
            width: 100%;
        }
    }

    &__inner {
        height: auto;
        overflow-y: auto;
        padding-bottom: remy(250px);

        @include mq(md) {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            flex: 1 1 0;
            padding: 0;
        }
    }

    &__link {
        font-size: 1rem;
        color: $black;
        border-bottom: $root-border;
        display: block;
        padding: calc(2px + .8125rem) 0;

        @include mq(md) {
            color: $white;
            position: relative;
            border: 0;
            display: inline-block;
            padding: calc(2px + .8125rem);

            .nav__main li:hover > & {
                background: $white;
                color: $root-color;
            }
        }

        .gr-icon-angle-down {
            margin-left: em(5px);

            @include mq(md) {
                display: none;
            }
        }
    }

    &__main {
        text-transform: uppercase;
        @include mq(md) {
            margin-left: 180px;
            margin-right: 200px;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &__top {
        @include mq(md) {
            position: absolute;
            height: (.5 * $nav-height);
            right: (-.5 * $grid-gutter-width);
            left: calc(-100px - (.5 * #{$grid-gutter-width}));
            top: 0;
            padding: 0 (.5 * $grid-gutter-width);
            display: flex;
            align-items: center;
            justify-content: flex-end;
            z-index: 43;
        }

        .nav__link {
            text-transform: uppercase;
        }
    }

    &__sub {
        font-size: 1rem;
        display: none;
        background: $white;
        padding: 1rem;
        color: $root-color;

        @include mq(md) {
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            box-shadow: 0 5px 7.5px rgba(0, 0, 0, .3);
            z-index: 43;

            .nav__main li:hover > & {
                display: block;
            }
        }

        @media (max-width: #{map-get($grid-breakpoints, md)} + em(1px, 16px)) {
            &.is-open {
                display: block;
            }
        }
    }

    &__sub__link {
        display: block;
        color: inherit;
        text-transform: uppercase;
        color: $root-color;
        padding-top: .5em;
        transition: color $transition-normal ease-in-out;
    }

    .cta--mobile {
        display: flex;
        align-items: center;

        .btn--primary {
            font-size: .9em;
        }

        @include mq(md) {
            display: none;
        }
    }

    .cta--desktop {
        display: none;

        @include mq(md) {
            display: inline-block;
            &:hover {
                background-color: $color-secondary;
            }
        }
    }
}
