
$slick-opacity-default: .75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: .25 !default;

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 40px;
    width: 40px;
    cursor: pointer;
    background: color(greys, xdark);
    color: transparent;
    bottom: 0;
    padding: 0;
    border: none;
    outline: none;
    z-index: 5;

    &:hover,
    &:focus {
        outline: none;
        background: color(greys, xdark);
        color: transparent;
        &::before {
            opacity: $slick-opacity-on-hover;
        }
    }

    &.slick-disabled::before {
        opacity: $slick-opacity-not-active;
    }

    &::before {
        font-family: $gr-icon-font;
        height: 40px;
        width: 40px;
        font-size: 20px;
        line-height: 1;
        font-weight: bold;
        color: $white;
        opacity: $slick-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        display: inline-block;
        line-height: 40px;
    }
}

.slick-prev {
    left: 0;

    &::before {
        content: $gr-icon-angle-left;
    }
}

.slick-next {
    left: 40px;

    &::before {
        content: $gr-icon-angle-right;
    }
}

