// Components: Form Reservation
// --------------------------------------------------------------------------

.form--reservation {

    fieldset {
        @extend %section;
    }

    counter-reset: step;

    legend {
        position: relative;
        font-size: 1.5rem;
        padding-top: $spacer-sm;
        font-weight: 700;
        display: flex;
        align-items: center;
        line-height: 1;
        padding-left: 3.5rem;

        &::before {
            position: absolute;
            left: 0;
            top: 24px;
            content: counters(step, '.');
            counter-increment: step;
            color: $white;
            background: $color-primary;
            height: 3rem;
            width: 3rem;
            display: inline-block;
            text-align: center;
            line-height: 48px;
            //line-height: 3rem;
            border-radius: 100%;
            margin-right: 1rem;
            flex: 0 0 3rem;
        }
    }

    .step-1 .form-item--inline {
        @extend %row;

        .radio-wrapper {
            @extend %col;

            position: relative;
            margin-bottom: .5em;

            @include mq(sm) {
                flex: 1 1 50%;
                max-width: 50%;
            }

            @include mq(md) {
                flex: 1 1 25%;
                max-width: 25%;
                margin-bottom: 0;
            }

            label {
                border: 1px solid $color-border;
                width: 100%;
                padding: 1.5rem 1rem 1.5rem 3rem;
                font-size: .875rem;

                &::before {
                    top: 1.5rem;
                    left: 1rem;
                }

                &::after {
                    top: calc(#{$form-element-height}/2 * .25 + 1.5rem);
                    left: calc(#{$form-element-height}/2 * .25 + 1rem);
                }
            }

            .radio-hidden:checked + label {
                background: color(greys, xlight);

                .infos strong {
                    color: $color-primary;
                }
            }

            .infos {
                position: absolute;
                right: 0;
                bottom: 0;
                top: 0;
                padding: 1.25rem 1rem;
                font-size: .875rem;
                font-weight: 700;
                text-align: center;
                line-height: 1;

                strong {
                    font-size: 1rem;
                }
            }
        }
    }

    .step-2 .form-item--inline {
        @extend %row;

        &::after {
            display: block;
            content: "";
            width: 100%;
            height: 1px;
            background-color: $color-border;
            margin-left: ($grid-gutter-width / 2);
            margin-right: ($grid-gutter-width / 2);
        }

        .checkbox-wrapper {
            //background: color(greys, light);
            @extend %col;
            //flex: 1 1 0;
            max-width: 50%;
            flex-basis: 50%;

            label {
                width: 100%;
                //border-bottom: 1px solid $color-border;
                padding: 1rem 1rem 1rem 2rem;
                font-size: .875rem;

                &::before,
                &::after {
                    top: 1rem;
                }
            }

            & + .infos {
                //position: absolute;
                //right: 0;
                //bottom: 1px;
                padding: 1rem;
                font-size: .875rem;
                font-weight: 700;
                z-index: -1;
                //border-bottom: 1px solid $color-border;
                max-width: 50%;
                flex-basis: 50%;
            }
        }

        @include mq(sm) {
            padding-right: (2 * $root-hgrid);

            .checkbox-wrapper + .infos {
                right: (2 * $root-hgrid);
            }
        }

    }
}

// fix on load
.form {
    input[type="checkbox"],
    input[type="radio"] {
        display: none;
    }
}
