// ==========================================================================
// Styleguide
// ==========================================================================

// Styleguide: Typography
// --------------------------------------------------------------------------

@include typography('.typography, .redactor-box .redactor-editor');

.typography,
.redactor-box .redactor-editor {
    font-size: .875rem;

    [class^='btn--'] {
        margin-bottom: $root-vgrid;
    }

    dt,
    dd {
        border-left: 5px solid $color-primary;
        padding-left: $root-hgrid;
    }

    dt {
        @extend %title;
        @extend %title-small;
        padding-bottom: .5em;
    }

    dd {
        margin-bottom: $root-vgrid;
    }

    a:not([class]) {
        color: $color-primary;
    }

}

blockquote {
    font-size: .875em;

    footer {
        font-size: .875em;
        margin-top: 1em;
    }
}

%unordered-list,
%ordered-list {
    li::before {
        color: $color-primary;
    }
    li + li,
    li + li ul,
    li + li ol {
        margin-top: (.5 * $root-vgrid);
    }
}

%unordered-list {
    li {
        padding-left: 2.5rem;
        padding-top: .25rem;

        & + li,
        & + li ul {
            margin-top: (.5 * $root-vgrid);
        }

        &::before {
            font-family: $gr-icon-font;
            content: $gr-icon-check;
            border: 1px solid $color-primary;
            height: 2rem;
            width: 2rem;
            border-radius: 100%;
            text-align: center;
            line-height: strip-unit(2rem) * $root-font-size;
        }
    }
}

%title {
    font-weight: 700;
    font-size: 1.5rem;
    color: currentColor;
    position: relative;
    line-height: 1.1;
}

%title--underlined {
    @extend %title;
    text-transform: uppercase;
    padding-bottom: .875em;
    color: currentColor;

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        height: 2px;
        width: 30px;
        background: $color-primary;
    }
}


%title-big {
    font-size: 1.875rem;
}

%title-small {
    font-size: 1.125rem;
}

%title-center {
    text-align: center;

    &::after {
        left: calc(50% - 15px);
    }
}

.banner--home__title {
    @extend %title--underlined;
    @extend %title-big;
}

.banner__title {
    @extend %title--underlined;
    @extend %title-center;
}

.wide-cta__title,
.section__title {
    @extend %title--underlined;
    margin-bottom: $spacer-sm;
}

.section__subtitle {
    margin-top: $spacer-sm;
    margin-bottom: $spacer-sm;
}

.footer__title {
    @extend %title--underlined;
    @extend %title-small;
    font-weight: 400;
    margin-bottom: $root-vgrid;
}

.tab-content__title,
.card--contact__name,
.blogpost__title {
    @extend %title;
    padding-bottom: .25em;
}

.tab-content__title {
    text-transform: uppercase;
}

.aside__title {
    @extend %title-small;
    font-weight: 700;
    text-transform: uppercase;
    background: color(greys, xdark);
    color: $white;
    text-align: center;
    padding: $root-vgrid $root-hgrid;

    i {
        margin-right: .5em;
    }
}

// Styleguide: Buttons
// --------------------------------------------------------------------------

%btn {
    @include btn-size(.875rem, .725em, .875em, 0);
    // @include mq(sm) {
    //     @include btn-size(1rem, .8125rem, 1.25rem, 0);
    // }

    display: inline-block;
    text-transform: uppercase;
    border-style: solid;
    border-width: 2px;
    transition: $transition-normal ease-in-out;
    cursor: pointer;
}

.btn--primary {
    @extend %btn;
    @include btn-colors($white, $color-primary, $color-primary);
    @include mq(md) {
        &:hover {
            @include btn-colors(inherit, transparent, $color-primary);
        }
    }
}

.btn--ghost {
    @extend %btn;
    @include btn-colors($color-primary, transparent, $color-primary);
    @include mq(md) {
        &:hover {
            @include btn-colors($white, $color-primary, $color-primary);
        }
    }
}

.btn--ghost-white {
    @extend %btn;
    @include btn-colors($white, transparent, $white);
    @include mq(md) {
        &:hover {
            @include btn-colors($color-primary, $white, $white);
        }
    }
}

.btn--center {
    display: table;
    margin-left: auto;
    margin-right: auto;
}

// Styleguide: Forms
// --------------------------------------------------------------------------

@include form();
@include form-radio($form-element-height/2, $color-secondary);
@include form-checkbox($form-element-height/2, $color-secondary);
@include form-select();
@include form-validation();

.form {
    .btn--primary {
        float: right;
    }
    
    .btn-group {
        text-align: right;
        
        .btn--primary {
            margin-bottom: $root-vgrid / 2;
            float: none;
        }
    }
    
    fieldset + button {
        margin-top: $root-vgrid;
        margin-left: auto;
        display: flex;
    }
}

.form .form-item--inline {
    margin-bottom: .5rem;

    label {
        text-transform: uppercase;
        flex: 1 0 100%;
        font-size: .75em;
        font-weight: 600;
        line-height: 1.1;
        display: flex;
        align-items: center;
        height: 2.75rem;

        @include mq(xs) {
            flex: 0 0 144px;
        }

        @include mq(sm) {
            flex: 1 0 100%;
        }

        @include mq(md) {
            flex: 0 0 144px;
        }
    }

    .file-upload-wrapper label,
    .radio-wrapper label,
    .checkbox-wrapper label {
        text-transform: none;
        flex: 1 1 100%;
        font-weight: 400;
        height: auto;
        line-height: 1.375rem;
    }
}

.form .select-wrapper {
    flex: 1 0 100%;
}

@include mq(xs) {
    .form .select-wrapper {
        flex: 1 0 auto;
    }

    .form .form-item--inline.form-item--half input:not([type='submit']) {
        max-width: calc((100% - 143px) / 2);
    }

    .picker__holder {
        max-width: calc(100% - 164px);
    }
}

@include mq(sm) {
    .form .select-wrapper {
        flex: 1 0 0;
    }

    .picker__holder,
    .form .form-item--inline.form-item--half input:not([type='submit']) {
        max-width: 100%;
    }
}

@include mq(md) {
    .form .select-wrapper {
        flex: 1 0 auto;
    }

    .form .form-item--inline.form-item--half input:not([type='submit']) {
        max-width: calc((100% - 143px) / 2);
    }

    .picker__holder {
        max-width: calc(100% - 164px);
    }
}

.form .checkbox-wrapper .checkbox-hidden + label::before,
.form .radio-wrapper .radio-hidden + label::before {
    background: color(greys, xlight);
}

fieldset:not(.step-1) .form-item--inline .parsley-errors-list {
    margin-left: 164px;
}

fieldset.step-1 .form-item--inline .parsley-errors-list {
    margin-left: 10px;
}

.parsley-error :not(label),
.parsley-error .file-upload-wrapper label {
    font-size: .9em;
}

// Styleguide: UI
// --------------------------------------------------------------------------

.social-icons--link {

    @extend %social-icons;

    a {
        color: $color-primary;

        & + a {
            margin-left: .25em;
        }

        span {
            @extend %sr-only;
        }

        &::before {
            display: inline-block;
            width: 40px;
            height: 40px;
            text-align: center;
            border: 2px solid $color-primary;
            line-height: 40px;
            font-size: 1.25em;
            color: $color-primary;
            transition: $transition-normal ease-in-out;
        }

        &:hover::before {
            color: $white;
            background: $color-primary;
        }
    }
}

.social-icons--share {
    @include socials('background');

    a {
        color: $white;
        padding: $root-padding;

        span {
            padding-left: $root-padding;
        }
    }
}

@include breadcrumb();

.breadcrumb {
    padding: 0 (.5 * $grid-gutter-width) 1rem;
    border-bottom: 1px solid $color-border;
    max-width: calc(#{$grid-container-width} - #{$grid-gutter-width});
    width: 100%;
    margin: $spacer-sm auto;
}

.breadcrumb li a {
    color: $root-color;
    text-transform: uppercase;
}

.breadcrumb li::after {
    color: $color-primary;
}

@include lazyload();

.pagination {
    margin-top: $spacer-sm;
    margin-bottom: $spacer-sm;
    padding-top: $spacer-sm;
    border-top: 1px solid $color-border;

    text-align: right;
    font-size: 0;

    .pagination__link,
    .pagination__link--current,
    .pagination__link--disabled {
        font-size: 1rem;
        text-align: center;
        display: inline-block;
        color: $root-color;
        margin: 0 0 0 -2px;
        border: 2px solid $color-border;
        height: 4em;
        width: 4em;
        line-height: calc(4em - 4px);
    }

    .pagination__link--current {
        color: $color-primary;
    }
}
