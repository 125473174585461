// ==========================================================================
// Socials
// ==========================================================================

// Socials icons/color mapping
// --------------------------------------------------------------------------

$socials: (
    twitter: (
        char: $gr-icon-twitter,
        color: #55acee
    ),
    google: (
        char: $gr-icon-googleplus,
        color: #dd4b39
    ),
    facebook: (
        char: $gr-icon-facebook,
        color: #3b5998
    ),
    pinterest: (
        char: $gr-icon-pinterest,
        color: #bd081c
    ),
    instagram: (
        char: $gr-icon-instagram,
        color: #3f729b
    ),
    linkedin: (
        char: $gr-icon-linkedin,
        color: #0077b5
    ),
    mailto: (
        char: $gr-icon-mail,
        color: #f00
    ),
    rss: (
        char: $gr-icon-rss,
        color: #f60
    ),
    vimeo: (
        char: $gr-icon-vimeo,
        color: #f60
    ),
    youtube: (
        char: $gr-icon-youtube,
        color: #f60
    )
);


// @function social-set()
// --------------------------------------------------------------------------
// Returns a value from the specified set with the specified key
//
// @example scss - Usage
// .twitter {
//     background-color: social-set('twitter', 'color');
//     &::after {
//         content: social-set('twitter', 'char');
//     }
// }
//

@function social-set($set, $key) {
    @return map-get(map-get($socials, $set), $key);
}


// placeholder %social-icons
// --------------------------------------------------------------------------

%social-icons {
    a {
        display: inline-block;

        &::before {
            font-family: $gr-icon-font;
            line-height: inherit;
            font-size: 1em;
        }

        @each $social, $icon in $socials {
            &[href*='#{$social}']::before {
                content: map-get($icon, char);
            }
        }
    }
}

// mixin socials($property)
// --------------------------------------------------------------------------

@mixin socials($property: 'color') {
    @extend %social-icons;

    @if ($property == 'color') {
        @each $social, $icon in $socials {
            [href*='#{$social}'] {
                color: map-get($icon, color);
            }
        }
    }

    @if ($property == 'background') {
        @each $social, $icon in $socials {
            [href*='#{$social}'] {
                background: map-get($icon, color);
            }
        }
    }
}
