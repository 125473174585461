// ==========================================================================
// Banners
// ==========================================================================

// <section class="$flex-banner-class">
//     <div class="$flex-banner-class__cover"><img src="" alt=""></div>
//     <div class="$flex-banner-class__inner"> ... </div>
// </header>

@mixin flex-banner($flex-banner-class: '.flex-banner', $flex-banner-height: 100vh, $flex-banner-padding: 0, $flex-banner-breakpoint: sm) {

    #{$flex-banner-class} {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        @if map-has-key($grid-breakpoints, $flex-banner-breakpoint) {
            @include mq($flex-banner-breakpoint) {
                min-height: $flex-banner-height;

                &::after {
                    content: '';
                    display: inline-block;
                    min-height: $flex-banner-height;
                    visibility: hidden;
                    z-index: -1;
                }
            }
        }
    }

    #{$flex-banner-class}__cover {
        @extend %img-cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 11;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #{$flex-banner-class}__inner {
        padding: $flex-banner-padding $grid-gutter-width/2;
        position: relative;
        text-align: center;
        z-index: 12;

        @if map-has-key($grid-breakpoints, $flex-banner-breakpoint) {
            @include mq($flex-banner-breakpoint) {
                width: 100%;
                max-width: $grid-container-width;
            }
        }
    }
}
