// ==========================================================================
// Main
// ==========================================================================

// “One file to rule them all,
//  One file to find them,
//  One file to bring them all,
//  And in the Sass way merge them.”

@import '../../ground/styles/ground';

$white: color(root, white);
$black: color(root, black);
$color-primary: color(brand, primary);
$color-secondary: color(brand, secondary);
$color-error: color(validation, error);
$color-success: color(validation, success);

$color-border: color(greys, light);

$spacer-sm: 2.25rem;

$de-icon-font: 'DEIcons';

@include font-face($de-icon-font, #{$font-path}/eastman, normal, normal);

[class*='eastman-']::before {
    font-family: $de-icon-font !important; // Use !important to prevent issues with browser extensions that change fonts
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
}

$eastman-categories: "\e900";
$eastman-chambres: "\e901";
$eastman-commodites: "\e902";
$eastman-icon_separation: "\e903";
$eastman-journee: "\e904";
$eastman-lac-epaule: "\e905";
$eastman-localisation: "\e906";
$eastman-populaires: "\e907";
$eastman-quote: "\e908";
$eastman-reunion_affaire: "\e909";
$eastman-salles: "\e90a";
$eastman-team_building: "\e90b";

.eastman-categories::before {
    content: $eastman-categories;
}

.eastman-chambres::before {
    content: $eastman-chambres;
}

.eastman-commodites::before {
    content: $eastman-commodites;
}

.eastman-icon_separation::before {
    content: $eastman-icon_separation;
}

.eastman-journee::before {
    content: $eastman-journee;
}

.eastman-lac-epaule::before {
    content: $eastman-lac-epaule;
}

.eastman-localisation::before {
    content: $eastman-localisation;
}

.eastman-populaires::before {
    content: $eastman-populaires;
}

.eastman-quote::before {
    content: $eastman-quote;
}

.eastman-reunion_affaire::before {
    content: $eastman-reunion_affaire;
}

.eastman-salles::before {
    content: $eastman-salles;
}

.eastman-team_building::before {
    content: $eastman-team_building;
}

@import '../../../node_modules/pickadate/lib/compressed/themes/classic';
@import '../../../node_modules/pickadate/lib/compressed/themes/classic.date';
@import '../../../node_modules/magnific-popup/dist/magnific-popup';
@import '../../../node_modules/slick-carousel/slick/slick.scss';
@import 'slick-theme';

.picker {
    left: 0;
}

.picker__holder {
    right: 0;
    top: 43px;
    //max-width: calc(100% - 164px);
    border-color: $color-border;
}

.picker__footer {
    display: none;
}

.picker__day--today::before {
    border-top-color: $color-primary;
}

// ==========================================================================
// Demo Custom Styles
// ==========================================================================

// Start your custom styling by:
// 1 - If it exists, including a groots mixin with the corresponding parameters.
// 2 - Adding complémentary code.


// Layout: 12-column Responsive Grid
// --------------------------------------------------------------------------

@include grid('gr-grid', 12, $grid-gutter-width, $grid-container-width);

// Layout: Globals
// --------------------------------------------------------------------------

main {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
    padding-bottom: $spacer-sm;
}

hr {
    margin: $root-vgrid auto;
    width: 100%;
    max-width: $grid-container-width - $grid-gutter-width;
    height: 1px;
    background: $color-border;
    position: relative;
    overflow: visible;

    &::before {
        position: absolute;
        top: -1em;
        left: 50%;
        margin-left: -1em;
        font-family: $de-icon-font;
        font-size: 2em;
        content: $eastman-icon_separation;
        width: 2em;
        height: 2em;
        display: inline-block;
        text-align: center;
        line-height: 2;
        background: $white;
        color: $color-border;
    }

    @include mq(sm) {
        margin: (2 * $root-vgrid) auto;
    }

    .typography &::before {
        display: none;
    }
}

.iframe-wrapper {
    @include keep-ratio('16/9');

    iframe {
        left: (.5 * $grid-gutter-width);
        right: (.5 * $grid-gutter-width);
        width: calc(100% - #{$grid-gutter-width});
    }
}

%section,
.section {
    padding-top: $spacer-sm;
    padding-bottom: $spacer-sm;
}

aside,
.cards--blogposts,
.cards--activities,
.cards--clients,
.cards--partners,
.cards--contacts,
.tabs {
    padding-bottom: (2 * $spacer-sm);
}


.form--contact form,
.tabs {
    padding-top: $spacer-sm;
}


.footer {
    font-size: .875rem;
    //padding-bottom: $spacer-sm;

    a {
        color: $root-color;
    }

    [gr-grid='row'] > [gr-grid] {
        margin-top: $spacer-sm;
    }

    .social-icons--link {
        margin-bottom: $root-vgrid;
    }
}

.footer ul {
    list-style-type: none;

    li + li {
        margin-top: (.5 * $root-vgrid);
    }
}

.footer__cr {
    padding-top: $spacer-sm;
    padding-bottom: $spacer-sm;
    
    @include mq(sm) {
        display: flex;
    }

    p {
        @include mq(sm) {
            flex: 1;
        }
    }

    .globalia {
        margin-top: $spacer-sm / 2;

        @include mq(sm) {
            text-align: right;
            margin-top: 0;
        }

        svg {
            width: 80px;
            height: 20px;

            * {
                fill: $color-globalia;
            }
        }
    }
}

// Styleguide
// --------------------------------------------------------------------------
@import 'styleguide';

// Components
// --------------------------------------------------------------------------
@import 'navigation';
@import 'banners';
@import 'cards';
@import 'form--reservation';


// Components: Specific Layout
// --------------------------------------------------------------------------

.wide-cta {
    background: color(greys, xdark);
    color: $white;
    margin: 0;

    .img-cover {
        padding: 0;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 3rem;
            right: 0;
            width: 0;
            height: 0;
            border-top: 1.5rem solid transparent;
            border-bottom: 1.5rem solid transparent;
            border-right: 1.5rem solid color(greys, xdark);
            z-index: 20;
        }

        img {
            position: absolute;
        }
    }

    .typography {
        padding: 4.5rem 3rem 3rem;
    }
}

.demo-listing {
    [gr-lazyloader] {
        @include keep-ratio('16/9');
    }
}

// Components: Modal
// --------------------------------------------------------------------------

@include modal('.modal', xs, 25rem);

[gr-modal-content] header {
    margin-top: 0;
    text-align: center;
}

// Components: Expandable
// --------------------------------------------------------------------------

.accordion-tabs {

    .tab {
        list-style: none;
    }

    .tab-link {
        display: block;

        @extend %title-small;
        font-weight: 700;
        text-transform: uppercase;
        background: color(greys, xdark);
        color: $white;
        text-align: center;
        padding: $root-vgrid $root-hgrid;

        &.is-active {
            background: $color-secondary;
            color: color(greys, xdark);
        }
    }

    .tab-content {
        display: none;
        width: 100%;
        padding: (.5 * $root-vgrid) (.5 * $root-hgrid);
        border: 1px solid color(greys, light);
        background: color(greys, xlight);
        margin: 0;

        @include mq(sm) {
            width: calc((2 * #{$root-hgrid}) + 100%);
            padding: (2 * $root-vgrid) (2 * $root-hgrid);
            margin-left: -$root-hgrid;
            margin-right: -$root-hgrid;
        }

        .typography {
            padding: 0;
            @include mq(sm) {
                padding-left: $root-hgrid;
            }
        }

        .slideshow {
            padding: 0;
            margin-bottom: $root-vgrid;
            @include mq(sm) {
                padding-right: $root-hgrid;
            }
        }
    }

    @include mq(sm) {
        .tab {
            display: inline;
        }
        .tab-link {
            display: inline-block;
        }
        .tab-content {
            float: left;
        }
    }
}


.gallery {
    [gr-grid*='block'] {
        flex-basis: 50%;
        max-width: 50%;
    }

    [gr-lazyloader] {
        @include keep-ratio('16/9');
    }
}

.activity__schedule {
    [gr-lazyloader] {
        @include keep-ratio();
    }

    &__title {
        @extend %title;
        padding-bottom: $spacer-sm;
    }

    &__picture {
        margin-bottom: (2 * $spacer-sm);
    }

    .branding {
        background: color(greys, xdark);
        height: 3rem;
        position: absolute;
        z-index: 14;
        bottom: 0;
        left: 0;
        right: 0;

        &::before {
            content: '';
            position: absolute;
            width: 140px;
            height: 140px;
            background: color(greys, xdark);
            bottom: -30px;
            left: 40px;
            border-radius: 100%;
        }

        &::after {
            content: url('/dist/img/svg/DomaineEastman_logo_white.svg');
            width: 100px;
            position: absolute;
            bottom: 20px;
            left: 60px;
            height: 70px;
        }
    }

    @include mq(sm) {
        padding-top: $spacer-sm;

        .typography {
            padding-left: (3 * $root-hgrid);
        }
    }
}


.alert {
    position: relative;
    padding: 2em;
    color: $white;
    background-color: $color-primary;
    text-align: center;

    a {
        margin-left: ($grid-gutter-width / 2);
    }
}

.demo-map {
    height: 45vh;
    width: 100%;
}

@include mq(sm) {
    .form--contact form {
        padding-right: $root-hgrid;
    }
}


// Components: Google Map
// --------------------------------------------------------------------------

.blogpost__date {
    padding-bottom: $spacer-sm;
}

.blogpost__tools {
    display: flex;
    justify-content: space-between;
    padding-top: $spacer-sm;
    padding-bottom: $spacer-sm;
}

.aside__categories {
    background: color(greys, xlight);
    margin-bottom: $spacer-sm;
    padding: $root-vgrid $root-hgrid;
    list-style: none;

    li + li {
        margin-top: .5em;
    }

    li::before {
        content: '';
        font-family: 'GrootsIcons';
        font-size: 1.125em;
        content: $gr-icon-angle-right;
        color: $color-primary;
        margin-right: .5em;
        font-weight: bold;
    }

    a {
        text-transform: uppercase;
        color: $root-color;
    }
}

.white-popup {
    position: relative;
    background: $white;
    width: auto;
    max-width: 748px;
    margin: 20px auto;
    padding: (2 * $root-vgrid) (2 * $root-hgrid);

    &.typography {
        text-align: center;
    }

    blockquote {
        padding: $root-vgrid 0;
    }

    blockquote footer::before {
        font-family: $de-icon-font;
        content: $eastman-quote;
        display: block;
        font-size: 1rem;
    }

    img {
        width: 150px;
        height: auto;
        margin: auto;
    }
}

.thumb {
    max-width: 100%;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
