// Components: Banners
// --------------------------------------------------------------------------

@include flex-banner('.banner', auto, 3rem, 0);
@include flex-banner('.banner--top', 45vh, 3rem);
@include flex-banner('.banner--home', 90vh, 3rem);

.banner,
.banner--top,
.banner--home {
    color: $white;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 12;
        background-color: rgba(64, 54, 54, .4);
        box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
    }

    .typography {
        margin-top: 0;
    }
}

.banner--top__inner {
    padding-top: 8rem;
}

.banner--home__inner {
    padding-top: 8rem;
    text-align: left;

    @include mq(sm) {
        .typography {
            max-width: 50%;
        }
    }
}

h1.banner--home__title {
    line-height: 1.4;
}

.banner__inner {
    width: 100%;
    //margin-left: auto;
    //margin-right: auto;
    max-width: calc(.8 * #{$grid-container-width});
}

.banner--pattern,
.banner--ghost,
.banner--footer {
    color: $root-color;
}

.banner--footer {
    &::before {
        background: url('/dist/img/banner-bg-pattern.jpg');
        box-shadow: none;
    }

    .banner__title::after {
        display: none;
    }
}

.banner--ghost {
    &::before {
        display: none;
    }
}

.banner--pattern {
    &::before {
        background: url('/dist/img/banner-bg-pattern.jpg');
    }
}
